var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("FormDrawer", {
    attrs: { loading: _vm.loading, width: 680, flat: "", title: "Edit item" },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "v-form",
              { ref: "form" },
              [
                _c(
                  "v-container",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                rules: [
                                  (v) => !!v || "Please enter a project index",
                                ],
                                label: "Project index",
                              },
                              model: {
                                value: _vm.formData.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "name", $$v)
                                },
                                expression: "formData.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-select", {
                              attrs: {
                                items: [
                                  "Foot Dao",
                                  "Body Massage",
                                  "Traditional Chinese Medicine",
                                  "SPA",
                                  "Package",
                                ],
                                rules: [
                                  (v) => !!v || "Please select an item type",
                                ],
                                label: "Item Type",
                              },
                              model: {
                                value: _vm.formData.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "type", $$v)
                                },
                                expression: "formData.type",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "v-radio-group",
                              {
                                attrs: {
                                  items: ["Public Items", "Other Items"],
                                  rules: [
                                    (v) =>
                                      !!v || "Please select an item category",
                                  ],
                                  label: "Item Category",
                                  row: "",
                                },
                                model: {
                                  value: _vm.formData.category,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "category", $$v)
                                  },
                                  expression: "formData.category",
                                },
                              },
                              [
                                _c("v-radio", {
                                  attrs: {
                                    color: "primary",
                                    label: "Public Items",
                                    value: "Public Items",
                                  },
                                }),
                                _c("v-radio", {
                                  attrs: {
                                    color: "primary",
                                    label: "Other items",
                                    value: "Other items",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "6" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            rules: [
                              (v) => !!v || "Please enter a display price",
                            ],
                            label: "author",
                          },
                          model: {
                            value: _vm.formData.price,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "price", _vm._n($$v))
                            },
                            expression: "formData.price",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "6" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            rules: [
                              (v) => !!v || "Please enter the total duration",
                            ],
                            label: "Total duration (minutes)",
                          },
                          model: {
                            value: _vm.formData.time,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "time", _vm._n($$v))
                            },
                            expression: "formData.time",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                rules: [
                                  (v) => !!v || "Please enter the cost ratio",
                                ],
                                label: "Rating (%)",
                                type: "number",
                              },
                              model: {
                                value: _vm.formData.percent,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "percent", _vm._n($$v))
                                },
                                expression: "formData.percent",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "v-radio-group",
                              {
                                attrs: {
                                  rules: [
                                    (v) =>
                                      typeof v === "boolean" ||
                                      "Please choose an exclusive room",
                                  ],
                                  color: "primary",
                                  label: "GitHub Source Code",
                                  row: "",
                                },
                                model: {
                                  value: _vm.formData.occupy,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "occupy", $$v)
                                  },
                                  expression: "formData.occupy",
                                },
                              },
                              [
                                _c("v-radio", {
                                  attrs: {
                                    value: false,
                                    color: "primary",
                                    ilabel: "No",
                                  },
                                }),
                                _c("v-radio", {
                                  attrs: {
                                    value: true,
                                    color: "primary",
                                    label: "yes",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "py-0", attrs: { cols: "12" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                rules: [
                                  (v) =>
                                    !!v || "Please enter the efficacy label",
                                  (v) =>
                                    (v && v.length <= 12) ||
                                    "Enter up to 12 characters",
                                ],
                                counter: "12",
                                label: "Efficacy label",
                              },
                              model: {
                                value: _vm.formData.tags,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "tags", $$v)
                                },
                                expression: "formData.tags",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("VImgUpload", {
                              ref: "upload",
                              attrs: { flat: "" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c("v-spacer"),
            _c(
              "v-btn",
              { attrs: { text: "", "x-large": "" }, on: { click: _vm.close } },
              [_vm._v(" Cancel ")]
            ),
            _c(
              "v-btn",
              {
                attrs: { text: "", type: "submit", "x-large": "" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" save ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v
      },
      expression: "visible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }